import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:id",
    name: "post",
    component: () => import("../views/post/_id.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const title = to.path.replace("/", "");

  if (title.length !== 0) {
    document.title = `Palmswap - ${title}`;
  } else {
    document.title = "Palmswap Blog";
  }
});

export default router;
